var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import gql from "graphql-tag";
import mixins from "vue-typed-mixins";
import AsyncStatus from "../../../utils/async";
import NotificationsMixin from "../NotificationsMixin";
export default mixins(NotificationsMixin).extend({
    props: {
        useBankTransfer: {
            type: Boolean,
            required: true,
        },
        total: {
            type: Object,
            required: true,
        },
        billingName: {
            type: String,
            required: true,
        },
        process: {
            type: Function,
            required: true,
        },
        viewerStudent: {
            type: Object,
            default: null,
        },
    },
    // TODO: Remove if turns out don't need
    fragments: {
        viewerStudent: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      fragment PaymentForm_viewerStudent on Student {\n        id\n      }\n    "], ["\n      fragment PaymentForm_viewerStudent on Student {\n        id\n      }\n    "]))),
    },
    data: function () {
        return {
            status: new AsyncStatus(),
            form: {
                paymentType: null,
            },
        };
    },
    computed: {
        paymentTypeOptions: function () {
            return __spreadArray([
                {
                    value: "checkout",
                    label: this.$t("Online (credit card, Alipay, WeChat Pay)").toString(),
                }
            ], (this.useBankTransfer
                ? [
                    {
                        value: "bank-transfer",
                        label: this.$t("Bank transfer").toString(),
                    },
                ]
                : []), true);
        },
    },
    methods: {
        onSubmit: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.status.run((function () { return __awaiter(_this, void 0, void 0, function () {
                                var valid;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.$validator.validate()];
                                        case 1:
                                            valid = _a.sent();
                                            if (!valid) {
                                                throw new Error(this.$t("Please fix the errors shown").toString());
                                            }
                                            return [2 /*return*/, this.process(this.form)];
                                    }
                                });
                            }); })())];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
});
var templateObject_1;
