import { render, staticRenderFns } from "./SignInOrPersonalInformationForm.vue?vue&type=template&id=aaf6c586"
import script from "./SignInOrPersonalInformationForm.vue?vue&type=script&lang=ts"
export * from "./SignInOrPersonalInformationForm.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports