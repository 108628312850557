var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import gql from "graphql-tag";
import { createEmptyPersonalInformationEntry, isValidPersonalInformationEntry, } from "../../../../data";
import SignInOrPersonalInformationForm from "../../../ui/SignInOrPersonalInformationForm.vue";
import PaymentForm from "../../../ui/payment/PaymentForm.vue";
import Success from "./Success.vue";
import mixins from "vue-typed-mixins";
import createCheckoutPendingPropertyPurchase from "../../../../api/mutations/createCheckoutPendingPropertyPurchase";
import NotificationsMixin from "../../../ui/NotificationsMixin";
import { nl2br } from "../../../../utils/filters";
import getAbsoluteUrl from "../../../../router/getAbsoluteUrl";
export default mixins(NotificationsMixin).extend({
    components: {
        SignInOrPersonalInformationForm: SignInOrPersonalInformationForm,
        PaymentForm: PaymentForm,
        Success: Success,
    },
    apollo: {
        propertyListingPayment: {
            query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        query PaymentQuery($id: ID!) {\n          propertyListingPayment(id: $id) {\n            id\n            propertyInfo\n            otherInfo\n            isPaid\n            contactFirstName\n            contactLastName\n            contactEmailAddress\n            listing {\n              id\n              slug\n              streetAddress\n            }\n            amountAud\n            amount {\n              amount\n              currency\n            }\n            enquiry {\n              id\n              fullName\n            }\n          }\n        }\n      "], ["\n        query PaymentQuery($id: ID!) {\n          propertyListingPayment(id: $id) {\n            id\n            propertyInfo\n            otherInfo\n            isPaid\n            contactFirstName\n            contactLastName\n            contactEmailAddress\n            listing {\n              id\n              slug\n              streetAddress\n            }\n            amountAud\n            amount {\n              amount\n              currency\n            }\n            enquiry {\n              id\n              fullName\n            }\n          }\n        }\n      "]))),
            variables: function () {
                return {
                    id: this.$route.params.id,
                };
            },
            result: function (result) {
                if (!result.data) {
                    return;
                }
                var payment = result.data.propertyListingPayment;
                if (payment.enquiry) {
                    this.step = "payment";
                    this.personalInformation = undefined;
                    return;
                }
                this.step = "personal";
                this.personalInformation = __assign(__assign({}, createEmptyPersonalInformationEntry()), { firstName: payment.contactFirstName, lastName: payment.contactLastName, emailAddress: payment.contactEmailAddress });
            },
        },
        viewerStudent: gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      {\n        viewerStudent {\n          id\n          ...PaymentForm_viewerStudent\n        }\n      }\n      ", "\n    "], ["\n      {\n        viewerStudent {\n          id\n          ...PaymentForm_viewerStudent\n        }\n      }\n      ", "\n    "])), PaymentForm.options.fragments.viewerStudent),
    },
    data: function () {
        return {
            step: "personal",
            personalInformation: createEmptyPersonalInformationEntry(),
        };
    },
    computed: {
        billingName: function () {
            if (!this.personalInformation) {
                if (!this.propertyListingPayment ||
                    !this.propertyListingPayment.enquiry) {
                    throw new Error("Invalid state");
                }
                return this.propertyListingPayment.enquiry.fullName;
            }
            return (this.personalInformation.firstName +
                " " +
                this.personalInformation.lastName);
        },
        propertyInfoHtml: function () {
            if (!this.propertyListingPayment) {
                return;
            }
            return nl2br(this.propertyListingPayment.propertyInfo);
        },
        otherInfoHtml: function () {
            if (!this.propertyListingPayment) {
                return;
            }
            return nl2br(this.propertyListingPayment.otherInfo);
        },
    },
    methods: {
        onProcessPayment: function () {
            return __awaiter(this, void 0, void 0, function () {
                var personalInformation, purchasingAgent, saveForNextTime, _a, enteredSaveForNextTime, enteredPurchasingAgent, restPersonalInformation, data;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            if (!this.propertyListingPayment) {
                                throw new Error("Invalid state, propertyListingPayment null");
                            }
                            if (this.personalInformation &&
                                !isValidPersonalInformationEntry(this.personalInformation)) {
                                throw new Error("Invalid personal information");
                            }
                            personalInformation = undefined;
                            purchasingAgent = undefined;
                            saveForNextTime = false;
                            if (this.personalInformation) {
                                _a = this.personalInformation, enteredSaveForNextTime = _a.saveForNextTime, enteredPurchasingAgent = _a.purchasingAgent, restPersonalInformation = __rest(_a, ["saveForNextTime", "purchasingAgent"]);
                                saveForNextTime = enteredSaveForNextTime;
                                personalInformation = restPersonalInformation;
                                purchasingAgent = enteredPurchasingAgent;
                            }
                            return [4 /*yield*/, this.$apollo.mutate(createCheckoutPendingPropertyPurchase({
                                    paymentId: this.propertyListingPayment.id,
                                    personalInformation: personalInformation,
                                    purchasingAgent: purchasingAgent,
                                    successUrl: getAbsoluteUrl(this.$store, this.$router, "/accommodation/payment/".concat(encodeURIComponent(this.propertyListingPayment.id), "/success") +
                                        (saveForNextTime ? "?saveForNextTime=1" : "")),
                                }))];
                        case 1:
                            data = (_b.sent()).data;
                            if (!data) {
                                throw new Error("There was an error creating the purchase");
                            }
                            window.location.href =
                                data.createCheckoutPendingPropertyPurchase.checkoutUrl;
                            return [2 /*return*/];
                    }
                });
            });
        },
        onPersonalInformationSubmit: function () {
            this.step = "payment";
        },
    },
    metaInfo: function () {
        if (!this.propertyListingPayment) {
            return {};
        }
        return {
            title: this.$t("Payment for {address}", {
                address: this.propertyListingPayment.listing.streetAddress,
            }).toString(),
        };
    },
});
var templateObject_1, templateObject_2;
